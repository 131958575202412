<route>
{
  "meta": {
    "requiresAuth": true,
    "breadcrumb": [
          { "title": "Home", "url": "/organisation/dashboard" },
          { "title": "Accommodations", "active": true }
      ],
    "title": "Accommodations",
    "resource": "accommodations",
    "action": "read"
  }
}
</route>
<template>
  <div class="accommodation">
    <transition name="slide-fade" mode="out-in">
      <router-view
        :is-editing.sync="isEditing"
        :editing-accommodation.sync="editingAccommodation"
        @newAccommodation="newAccommodation"
        @isEditing="editAccommodation"
      />
    </transition>
  </div>
</template>

<script>
// import { AccommodationDto } from '../../app/entities/tenant/accommodation.dto';
// @ is an alias to /src

export default {
  name: 'Accommodations',

  layout: 'AppMain',
  data() {
    return {
      editingAccommodation: {},
      isEditing: false,
    };
  },

  methods: {
    editAccommodation(accommodation) {
      this.editingAccommodation = accommodation;
      this.isEditing = true;
    },
    newAccommodation() {
      this.editingAccommodation = {};
      this.isEditing = false;
    },
  },
};
</script>
